import { Box, Button, Flex, Center, Heading, LightMode, Text } from '@chakra-ui/react'
import * as React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const ImageHeroCentered = ({ image, title, text }) => {
  return (
    <Box
      as="section"
      py="0"
      position="relative"
      minH="140px"
      h={{ base: "560px", md: "640px" }}
    >
      <Box position="relative" w="full" h="full" zIndex={2}>
        <Box
          maxW={{ base: "xl", md: "7xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
          h="full"
          zIndex={3}
          position="relative"
        >
          <Center
            flexDirection="column"
            textAlign="center"
            color="white"
            h="full"
          >
            <Heading size="2xl" fontWeight="extrabold" as="h1">
              {title}
            </Heading>
            <Text fontSize="lg" fontWeight="medium" mt="3">
              {text}
            </Text>
            <LightMode>
              <Button
                colorScheme="bullseyeOrange"
                size="lg"
                mt="6"
                fontWeight="bold"
                fontSize="md"
                as={GatsbyLink}
                to="/request-quote"
              >
                Get your Free Quote
              </Button>
            </LightMode>
          </Center>
        </Box>
      </Box>
      <Flex
        id="image-wrapper"
        position="absolute"
        insetX="0"
        insetY="0"
        w="full"
        h="full"
        overflow="hidden"
        align="center"
      >
        <Box position="relative" w="full" h="full">
          {image && <GatsbyImage image={image} alt={title} />}
        </Box>
      </Flex>
      <Box
        zIndex={1}
        position="absolute"
        w="full"
        h="full"
        top="0"
        left="0"
        bg="blackAlpha.600"
      />

      {/* <Box
        display={{ base: 'none', md: 'block' }}
        position="absolute"
        zIndex={4}
        w="full"
        bottom="0"
        py="4"
        bg="blackAlpha.400"
      >
        <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto">
          <SimpleGrid columns={{ base: 1, md: 3 }}>
            {features.map(feature => (
              <Box textAlign="center" color="white">
                <Text>{feature.text}</Text>
                <Text fontSize="3xl">{feature.icon}</Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Box> */}
    </Box>
  )
}

export default ImageHeroCentered