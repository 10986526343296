import React from "react"
import { graphql } from "gatsby"
import { Box, Grid, Heading, Text, useColorModeValue } from "@chakra-ui/react"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import Container from "../components/Container"
import FeatureCard from "../components/landingpage/FeatureCard"
import ImageHeroCentered from "../components/landingpage/ImageHeroCentered"

const ServiceCategory = ({ data }) => {
  const pageData = data.contentfulServiceCategory

  // const features = [
  //   {
  //     text: "featuretext",
  //     icon: "10",
  //   },
  //   {
  //     text: "featuretext2",
  //     icon: "11",
  //   },
  //   {
  //     text: "featuretext3",
  //     icon: "12",
  //   },
  // ]

  return (
    <Layout>
      <Meta title={pageData.title} description={pageData.heroDescription} />
      {pageData?.image?.gatsbyImage && (
        <ImageHeroCentered
          image={pageData.image.gatsbyImage}
          title={pageData.heroTitle}
          text={pageData.heroDescription}
          //features={features}
        />
      )}
      <Box
        pt={50}
        width={`100%`}
        background={useColorModeValue(`gray.100`, `gray.600`)}
        borderBottomWidth="1px"
      >
        <Container mt="0">
          <Box textAlign="center" mb="20">
            <Heading
              size="2xl"
              lineHeight="normal"
              color={useColorModeValue("green.600", "green.400")}
              letterSpacing="tight"
              fontWeight="extrabold"
            >
              {pageData.pageHeading}
            </Heading>
            <Text
              fontSize={{ base: "xl", lg: "2xl" }}
              mx="auto"
              mt={4}
              maxW="xl"
            >
              {pageData.pageDescription}
            </Text>
          </Box>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={10}
            px={{ md: 12 }}
            mb={`20`}
          >
            {pageData.services.map(service => (
              <FeatureCard
                image={
                  service?.serviceImage?.gatsbyImage
                    ? service.serviceImage.gatsbyImage
                    : false
                }
                title={service.title}
                link={service.urlSlug}
              >
                {service.serviceOverview.serviceOverview}
              </FeatureCard>
            ))}
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}

export const data = graphql`
  query($ContenfulId: String!) {
    contentfulServiceCategory(id: { eq: $ContenfulId }) {
      title
      heroDescription
      heroTitle
      pageDescription
      pageHeading
      image {
        gatsbyImage(
          aspectRatio: 1.778
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          layout: FULL_WIDTH
          width: 2400,
        )
      }
      services {
        title
        urlSlug
        serviceOverview {
          serviceOverview
        }
        serviceImage {
          gatsbyImage(
            layout: CONSTRAINED
            aspectRatio: 1.778
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 800,
          )
        }
      }
    }
  }
`

export default ServiceCategory
